@import "../../styles/base.scss";

.calendar-wrapper {
    border: 1px solid $greyscale-light;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 4em;

    .calendar-row {
        border-top: 1px solid $greyscale-light;
        height: 8em;

        .col {
            border-right: 1px solid $greyscale-light;

            &.month-col {
                overflow: auto;
            }


            div {
                .day {
                    float: right;

                    &.today {
                        background-color: $legacy-rcm-blue;
                        border-radius: 12px;
                        padding: 3px;
                        margin-bottom: .25em;
                        color: #ffffff;
                    }
                }

                width: 100%;
                padding-top: 0.5em;
                align-content: right;
                color: $text-primary-color-lighter;
            }

            &:nth-last-child(-n+2) {
                background-color: $greyscale-lightest;

                div {
                    .day {
                        float: right;

                        &.today {
                            background-color: $legacy-rcm-blue;
                            border-radius: 12px;
                            padding: 5px;
                            color: #ffffff;
                        }
                    }

                    color: $text-primary-color;
                }
            }

            &:nth-last-child(-n+1) {
                border-right: none;
            }

            &.calendar-cell {
                height: 8em;
                overflow: auto;
            }

            &.hour-row-content {

                .inner-row {
                    border: none;
                }

                &.all-day {
                    overflow: auto;
                    height: 75px;

                    .calendar-booking-card {
                        position: relative;
                        margin: 1em 0;
                        height: auto;
                    }
                }
            }
        }

        &.header-row {
            height: 5em;
            border-top: none;

            h1 {
                margin-left: 0.5em;
                margin-top: 1em;
                color: $text-primary-color-light;

                span {
                    font-family: $font-heading-2;
                }
            }

            border-bottom: none;
        }

        &.weekday-row {
            height: 2em;
            border-top: none;
            border-bottom: none;

            .weekday {
                border: none;
                background-color: white;

                span {
                    float: right;
                    font-family: $font-emphasis;
                    color: $text-primary-color-light;
                }
            }
        }

    }

    &.daily-view {
        .calendar-row {
            &.weekday-row {
                height: 2em;
                border-top: none;
                border-bottom: none;
                margin-bottom: 0.5em;

                .weekday {
                    border: none;
                    background-color: white;

                    span {
                        padding-left: 1em;
                        float: left;
                        font-family: $font-heading-2;
                        font-size: larger;
                        color: $text-primary-color-light;
                    }
                }
            }

            &.all-day-row {
                height: 5em;
                border-bottom: 5px solid $greyscale-light;

                .all-day-row-label {
                    display: flex;
                    align-items: center;
                    border-right: 1px solid $greyscale-light;

                    span {
                        display: table;
                        margin: 0 auto;
                    }
                }
            }

            &.hour-row {
                height: 60px;
                border-top: none !important;

                .hour-row-label {
                    display: flex;
                    align-items: flex-end;
                    border-right: 1px solid $greyscale-light;

                    span {
                        display: table;
                        margin: 0 auto;
                        margin-bottom: -0.7em;

                    }

                }

                .hour-row-content {
                    border-top: 1px solid $greyscale-light;
                }

                &:last-child {
                    span {
                        display: none;
                    }
                }

            }
        }

        .calendar-booking-card {
            width: -webkit-fill-available;
            margin-left: 1em;
            margin-right: 1em;
            height: 660px;
        }
    }

    &.weekly-view {
        .calendar-row {
            &.weekday-row {
                height: 2em;
                border-top: none;
                border-bottom: none;

                .weekday {
                    border: none;
                    background-color: white;

                    span {
                        float: unset;
                        display: table;
                        margin: 0 auto;
                        font-family: $font-emphasis;
                        color: $text-primary-color-light;

                        &.today {
                            background-color: $legacy-rcm-blue;
                            border-radius: 12px;
                            padding: 2px;
                            color: #ffffff;
                        }
                    }
                }
            }

            &.all-day-row {
                height: 5em;
                border-bottom: 5px solid $greyscale-light;

                .all-day-row-label {
                    display: flex;
                    align-items: center;
                    border-right: 1px solid $greyscale-light;

                    span {
                        display: table;
                        margin: 0 auto;
                    }
                }
            }

            &.hour-row {
                height: 60px;
                border-top: none !important;

                .hour-row-label {
                    display: flex;
                    align-items: flex-end;
                    border-right: 1px solid $greyscale-light;

                    span {
                        display: table;
                        margin: 0 auto;
                        margin-bottom: -0.7em;

                    }

                }

                .hour-row-content {
                    position: relative;
                    border-top: 1px solid $greyscale-light;
                }

                &:last-child {
                    span {
                        display: none;
                    }
                }

            }
        }

        .calendar-booking-card {
            z-index: 999;
            height: 660px;
            width: -webkit-fill-available !important;
            padding: 0 !important;
            margin-left: 1em;
            margin-right: -.9em;

            span {
                margin-left: .5em;
                word-wrap: break-word;
                overflow: hidden;
            }
        }
    }

    .calendar-booking-card {
        display: flex;
        flex-direction: column;
        background-color: $primary-color-light !important;
        border-color: $primary-color-light !important;
        border-radius: 5px;
        font-size: small;
        color: $text-primary-color !important;
        margin-bottom: .5em;
        padding-bottom: .5em;

        span {
            margin: 0 0.8em;

            &.booking-name {
                font-family: $font-emphasis;

                .repeating-icon-calendar {
                    float: inline-end;
                    color: red !important;
                    width: 12px;

                    &.small {
                        width: 10px;
                        padding-bottom: 4px;
                    }
                }
            }

            &.compact-text {
                vertical-align: middle;
                font-size: x-small;
            }

            &.booking-date {
                font-family: $font-body;
            }
        }

    }

    &.mobile {
        border-radius: 0;

        &.daily-view {
            margin: 0 -1.5em;
            width: 100vw;
            border-radius: 0;

            hr {
                margin: 0.6em 0;
                opacity: 10%;
            }

            .calendar-row {

                &.weekday-row {
                    margin: 0;
                    margin: 0.5em 0;

                    .weekday {
                        padding: 0;
                        display: contents;

                        span {
                            margin: 0 auto;
                            padding: 0;
                            font-family: $font-heading-1;
                            color: $greyscale-dark;
                            font-size: large;

                            &.selected {
                                display: inline-flex;
                                text-align: center;
                                color: white !important;
                                width: 30px;
                                height: 30px;
                                border: 3px solid $legacy-rcm-blue;
                                border-radius: 50%;
                                background-color: $legacy-rcm-blue;
                            }
                        }
                    }
                }

                &.all-day-row {
                    height: 4em;

                    .all-day-row-label {
                        width: 20%;
                    }

                    .all-day-row-content {
                        width: 80%;
                    }
                }

                &.hour-row {
                    height: 60px;

                    .hour-row-label {
                        width: 20%;
                    }

                    .hour-row-content {
                        width: 80%;
                    }

                }

                .calendar-booking-card {
                    height: 330px;
                    margin-right: -1em;
                }

                &:nth-last-child(-n+1) {
                    border-bottom: 5px solid $body-bg;
                }
            }
        }

        &.weekly-view {
            margin: 0 -1.5em;
            width: 100vw;
            border-radius: 0;

            overflow-y: hidden;
            overflow-x: auto;

            .calendar-row {
                width: 150vb;

                &.weekday-row {
                    height: 3em;

                    .weekday {
                        margin: auto;

                        span {
                            font-size: large;
                            font-family: $font-heading-1;
                            color: $greyscale-dark;


                            &.selected {
                                display: inline;
                                text-align: center;
                                color: white;
                                width: 26px;
                                height: 26px;
                                border: 3px solid $legacy-rcm-blue;
                                margin-left: 0.3em;
                                border-radius: 50%;
                                background-color: $legacy-rcm-blue;
                            }
                        }

                    }

                }

                &.all-day-row {
                    height: 4em;
                }

                &.hour-row {
                    height: 60px;
                }

                .calendar-booking-card {
                    height: 330px;
                }

                &:nth-last-child(-n+1) {
                    border-bottom: 5px solid $body-bg;
                }
            }
        }

        &.monthly-view {
            margin: 0 -1.5em;
            width: auto;

            .weekday-row {
                margin-top: 0.8em;

                .weekday {
                    display: inline-flex;
                    justify-content: center;

                    span {
                        font-family: $font-heading-2;
                    }
                }
            }

            hr {
                opacity: 10%;
                margin: 0 0 0.3em 0;
            }


            .date-row {
                border: none;
                height: 3em !important;

                &:nth-last-child(-n+1) {
                    border-bottom: 5px solid $body-bg;
                }

                .date {

                    border: none;
                    background-color: white;

                    div {
                        display: flex;
                        justify-content: center;
                    }

                    span {
                        color: $text-primary-color;
                        text-align: center;
                    }

                    &:nth-last-child(-n+1) {
                        span {
                            color: $text-primary-color-lighter;
                        }
                    }

                    &:nth-last-child(n+7) {
                        span {
                            color: $text-primary-color-lighter;
                        }
                    }

                    .dot {
                        span {
                            margin: -0.3em;
                            height: 6px;
                            width: 6px;
                            border-radius: 50%;
                        }

                        &.desk {
                            span {
                                background-color: $primary-color;
                            }
                        }
                    }

                    &.selected {

                        .day {
                            text-align: center;
                            color: white;
                            width: 26px;
                            height: 26px;
                            border: 1px solid $legacy-rcm-blue;
                            border-radius: 50%;
                            background-color: $legacy-rcm-blue;
                        }
                    }
                }
            }
        }
    }
}

.more-info-tooltip {

    &.mobile {
        display: none;
    }

    box-shadow: 0px 0px 8px #00000036;
    border-radius: 10px;

    .tooltip-inner {
        padding: 0.5em !important;
        min-width: 250px;
        background-color: white;
        color: $text-primary-color;
    }

    &.bs-tooltip-bottom {
        .tooltip-arrow {
            &:before {
                border-color: transparent !important;
                border-bottom-color: white !important;
            }
        }
    }

    &.bs-tooltip-top {
        .tooltip-arrow {
            &:before {
                border-color: transparent !important;
                border-top-color: white !important;
            }
        }
    }

    &.bs-tooltip-start {
        .tooltip-arrow {
            &::before {
                border-color: transparent !important;
                border-left-color: white !important;
            }
        }
    }

    &.bs-tooltip-end {
        .tooltip-arrow {
            &::before {
                border-color: transparent !important;
                border-right-color: white !important;
            }
        }
    }

    hr {
        margin: 0.5em;
    }

    .tooltip-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        position: relative;
        height: 40px;

        .desk-number-group-container {
            display: flex;
            min-width: 90%;

            .desk-number {
                padding-left: .1em;
                color: $legacy-rcm-blue;
                font-family: $font-title;
                font-size: 22pt;
                height: 1em;
                padding-top: .16em;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                overflow: hidden;
                min-width: 20%;
            }

            .desk-group-hierarchy {
                min-width: 20%;
                max-width: 80%;
                display: inline-grid;
                text-align: left;

                span {
                    padding: 0 !important;
                    margin: 0 0.5em -.1em .5em !important;
                    font-size: 11pt;
                    font-family: $font-body;
                    font-weight: lighter;

                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                    overflow: hidden;


                    &:first-child {
                        font-family: $font-emphasis;
                        padding-top: .6em;
                        margin-bottom: -.9em;
                    }
                }
            }

        }
    }

    .tooltip-details {
        display: flex;
        flex-direction: column;
        text-align: initial;
        margin: 0 0.7em;

        .short-date {
            font-family: $font-emphasis;
            font-size: small;
        }

        .time-interval {
            font-family: $font-body;
            font-size: small;
        }

        .issues {
            margin: 0.5em 0;

            .issue {
                font-family: $font-emphasis;
                font-size: small;
            }

            .error-icon {
                &:before {
                    content: "!";
                    background-color: $error-color;
                    color: white;
                    display: inline-block;
                    width: 21px;
                    height: 21px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 22px;
                    font-size: 16px;
                    font-family: $font-emphasis;
                    margin-right: 0.4em;
                }
            }
        }

        .repeating-icon {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .tooltip-tags {
        display: flex;
        flex-direction: row;
        text-align: initial;
        margin: 0 0.7em;
        padding-bottom: 0.3em;

        .tag {
            font-family: $font-emphasis;
            font-size: smaller;
            padding-right: 1em;
        }

    }
}

.selected-day-bookings {
    min-height: 15em;
    background-color: white;
    margin: 0 -1.5em;
    width: auto;

    .booking {
        margin: 1em 1.5em;
        display: flex;
        justify-content: space-between;

        border-left: 5px solid $primary-color;

        .name {
            font-size: large;
            padding-left: 0.8em;
            margin: auto 0;
        }

        .time {
            font-size: small;
            display: grid;
        }
    }
}