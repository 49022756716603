@import "../../styles/base.scss";

.my-bookings-page-wrapper {

    .page-header {
        margin-bottom: 1em;
    }

    h3 {
        margin-bottom: 1em;
    }

    hr {
        margin-bottom: 2em;
    }

    .add-booking-dropdown {
        margin-left: 1em;

        svg {
            padding-bottom: .1em;
        }

        .add-booking-dropdown-button {
            color: white !important;
            background-color: $primary-color !important;
            border-color: $primary-color !important;

            &:hover {
                background-color: darken($color: $primary-color, $amount: 10) !important;
                border-color: darken($color: $primary-color, $amount: 10) !important;
            }
        }

        .add-booking-dropdown-menu {
            margin-left: .5em;
            box-shadow: none;
            border: 1px solid $greyscale-medium;
            width: 50% !important;
            min-width: fit-content;
            padding: .3em;
        }

        ::after {
            display: none;
        }

        a {
            color: $text-primary-color;
            font-size: 14px;
            font-family: $font-body;

            &:hover {
                color: white;
                background: $primary-color;
                border-radius: 2px;
            }
        }
    }

    .search-box {
        align-self: flex-end;

        .input-group {
            input {
                border-radius: 6px !important;
                height: 40px;
                width: 190px;
            }

            &.filled {
                input {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    width: 150px;
                    height: 40px;
                }

                .clear-search-button {
                    height: 40px;
                    width: 40px;
                    border-left: none;
                    border-radius: 6px;
                }
            }
        }

    }

    .calendar-controls {
        margin-top: -1em;

        .date-controls {
            .input-group {
                button {
                    height: 1em;

                    &.date-controls-prev {
                        border-radius: 20px;
                        width: 1em;
                    }

                    &.date-controls-next {
                        border-radius: 20px;
                        width: 1em;
                    }
                }
            }
        }

        .date-scale-controls {
            .input-group {
                button {
                    height: 1em;
                    width: 6em;

                    &.active {
                        background-color: $primary-color;
                        border-color: $primary-color;
                        color: white;
                    }

                    &:hover {
                        border-color: $primary-color;
                    }
                }
            }
        }
    }

    .upcoming-bookings-container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0 -.8rem;
    }

    .show-more-bookings-container {
        display: flex;
        flex-direction: row-reverse;

        .btn {
            font-family: $font-emphasis;
            color: $legacy-rcm-blue;
            background-color: transparent !important;
            border: none !important;
            color: $legacy-rcm-blue !important;
            padding: 0 !important;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.edit-form {
    .col-2 {
        padding: 0 .3em;
        width: 15%;

        &:first-child {
            padding-right: 1em;
        }


        .toggle-switch-container {
            margin-left: 1.5em;

            input {
                display: none;
            }
        }
    }

    input {
        height: 44px;
    }

    .all-day-label {
        padding-bottom: .75em;
        font-weight: bold;
    }

    .toggle-switch-col {
        display: flex;
        align-items: flex-end;
    }

    .error-message {
        font-family: $font-emphasis;
        width: 100%;
        font-size: .875em;
        color: $error-color;
    }
}