@import "../../styles/base.scss";

.recurring-controls-container {
    background-color: white;
    border: 1px solid $greyscale-medium;
    border-radius: 5px;
    padding: 1.5em;
    margin: 1em 0 0 .3em;
    max-width: fit-content;

    &.compact {
        border: none;
        margin: 2em 0;
        padding: 0;
        padding-left: .5em;

        select,
        input {
            height: 2.3em;
        }

        .col {
            padding-left: .25em;
            padding-right: .25em;
        }

        .start-date-label {
            padding-right: .7em;
        }

        .start-date-col {
            padding-right: .7em;
        }

        .frequency-dynamic-label {
            padding-right: 1em;
        }

    }

    select,
    input {
        height: 2.5em;
    }

    .col {
        max-width: fit-content;
        padding-left: 0;
        padding-right: 0;
    }

    .form-label {
        padding-left: 1.5em;
        font-weight: normal !important;
    }

    .start-date-col {
        padding-left: 1em;
    }

    .frequency-dynamic-label {
        padding-left: 0;
    }

    .end-type-col {
        min-width: fit-content;
    }

    .end-value-col {
        max-width: fit-content;

        input {
            &.input-number {
                width: 5em;
            }
        }
    }
}