@import "../../styles/base.scss";

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@mixin loaderDivMixin {
    border-radius: 50%;
    padding: 8px;
    border: 2px solid transparent;
    animation: rotate linear 3.5s infinite;
}

.loader {
    position: relative;
    margin: 75px auto;
    width: 150px;
    height: 150px;
    display: block;
    overflow: hidden;

    div {
        height: 100%;
    }
}

/*loader 3  */
.loader3,
.loader3 div {
    @include loaderDivMixin;
    border-top-color: $primary-color;
    border-left-color: $secondary-color;
    animation-timing-function: cubic-bezier(.55, .38, .21, .88);
    animation-duration: 3s;
}


.loader,
.loader * {
    will-change: transform;
}