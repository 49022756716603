@import "../../styles/base.scss";

header {
    .header-content {
        height: 80px;
        background: $white;
        display: flex;
        align-items: center;
        padding: 0 3rem;
        box-shadow: none;
        border-bottom: 1px solid $light-grey;

        div {
            .logo-theme-color {
                fill: #fff;
            }

            svg {
                height: 57px;
                width: 200px;
            }

            &.reserva-header-tools {
                flex: 1;
                display: flex;
                justify-content: flex-end;

                .dropdown {
                    padding-right: 1.5rem;

                    button {
                        border: none !important;
                        box-shadow: none !important;
                    }

                    .dropdown-toggle {
                        margin: 0;
                        background-color: transparent;
                        border: none;

                        &:after {
                            display: none;
                        }
                    }

                    .dropdown-menu {

                        a {
                            color: $text-primary-color;
                            font-size: 14px;
                            font-family: $font-body;

                            &:hover {
                                color: white;
                                background: $primary-color;
                                border-radius: 2px;
                            }
                        }
                    }
                }

                button {
                    display: flex;
                    margin-right: 1.5rem;
                    cursor: pointer;
                    padding: 10px 0;
                    background: none;
                    border: none;
                    box-shadow: none;

                    &:last-child {
                        margin: 0
                    }

                    svg {
                        width: 30px;
                        height: 30px;
                    }

                    &.header-with-alert {
                        position: relative;

                        &:after {
                            content: attr(data-alert);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: $error-color;
                            border-radius: 50%;
                            width: 15px;
                            height: 15px;
                            text-align: center;
                            color: white;
                            font-size: 11px;
                            position: absolute;
                            bottom: 5px;
                            left: -5px;

                        }
                    }
                }
            }
        }

        &.mobile {
            height: 60px;
            background: $white;
            display: flex;
            align-items: center;
            padding: 0 1em;
            box-shadow: none;
            border-bottom: 1px solid $light-grey;

            div {
                .logo-theme-color {
                    fill: #fff;
                }

                svg {
                    width: 160px;
                }

                &.reserva-header-tools {
                    button {
                        svg {
                            width: 25px;
                            height: 25px;
                        }

                    }
                }
            }
        }

    }
}

.reserva-version-modal {
    svg {
        padding: 10px 0 20px 5px;
        height: 40%;
        width: 40%;
        position: relative;
    }

    .userinfo {
        margin-top: 0rem;
        padding-bottom: 1rem;
        font-size: medium;

        .username {
            font-weight: bold;
        }
    }
}