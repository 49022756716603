@import "../../styles/base.scss";

.reserva-pw-verification-container {
    background-color: #f5f6f8;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.reserva-pw-verification {
    width: 500px;
    position: relative;
    font-family: $font-body;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000010;
    background-color: $white;

    svg {
        margin: 0 1.5em 2em 1.5em;
    }

    p {
        align-self: center;
    }
}