@import "../../styles/base.scss";

.mobile-calendar-options {
    display: inline-flex;
    justify-content: space-between;

    .month {
        margin: auto 0;
        font-size: large;

        b {
            font-family: $font-heading-1;
        }

        svg {
            scale: 1.2;

            &:first-child {
                margin-right: 0.5em;
                transform: rotate(180deg)
            }

            &:last-child {
                margin-left: 0.5em;
            }
        }
    }

    .controls {
        display: inline-flex;
        margin: auto 0;

        .dropdown-toggle {
            margin-left: 0.5em;

            svg {
                scale: 2;

                path {
                    &:last-child {
                        fill: #898989 !important;
                    }
                }
            }

            &:active {
                color: black !important;
                background-color: white !important;
                border: 1px solid $greyscale-darker !important;

                svg {
                    scale: 2;

                    path {
                        &:last-child {
                            fill: #898989 !important;
                        }
                    }
                }
            }

            &:focus {
                color: black !important;
                background-color: white !important;
                border: 1px solid $greyscale-darker !important;

                svg {
                    scale: 2;

                    path {
                        &:last-child {
                            fill: #898989 !important;
                        }
                    }
                }
            }
        }

        .dropdown-toggle::after {
            display: none; // Hide the arrow
        }

        .dropdown-menu {
            width: min-content;
        }

        .dropdown-item {
            justify-content: space-between;
            display: inline-flex;
        }

        .dropdown-toggle::after,
        .dropdown-menu::before {
            display: none; // Hide the arrow
        }
    }

    margin-bottom: 1em;
}