@import "../../styles/base.scss";

.booking-card {
    margin: .4em !important;
    width: 255px;
    border: none;
    border-radius: 7px;
    border: 1px $greyscale-medium solid;
    padding: 4px;

    &.desk {
        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: white;
            height: 50px;
            font-size: 14px;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            position: relative;
            padding-left: .8em;
            padding-right: 0;
            border-bottom: none;

            .desk-number-group-container {
                display: flex;
                min-width: 90%;

                .desk-number {
                    color: $legacy-rcm-blue;
                    font-family: $font-title;
                    font-size: 24pt;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                    overflow: hidden;
                    min-width: 20%;
                }

                .desk-group-hierarchy {
                    min-width: 20%;
                    max-width: 80%;
                    display: inline-grid;

                    span {
                        margin-left: .5em;
                        font-size: 11pt;

                        text-overflow: ellipsis;
                        text-wrap: nowrap;
                        overflow: hidden;

                        &:first-child {
                            font-family: $font-emphasis;
                            padding-top: .6em;
                            margin-bottom: -.9em;
                        }
                    }
                }
            }
        }

        .card-header-body-separator {
            margin: .2em .8em;
        }

        .card-body {
            padding: .2em;

            .date-duration-issues-container {
                display: flex;
                flex-direction: column;
                text-align: initial;
                margin: 0 0.7em;
                min-height: 4.4em;

                .date {
                    font-family: $font-emphasis;
                    font-size: small;
                }

                .duration {
                    font-family: $font-body;
                    font-size: small;
                }

                .repeating-icon {
                    margin-left: auto;
                    margin-right: 0;
                }
            }

            .issues {
                margin-top: .5em;

                .issue {
                    font-family: $font-emphasis;
                    font-size: small;
                }

                .error-icon {
                    &:before {
                        content: "!";
                        background-color: $error-color;
                        color: white;
                        display: inline-block;
                        width: 21px;
                        height: 21px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 22px;
                        font-size: 16px;
                        font-family: $font-emphasis;
                        margin-right: 0.4em;
                    }
                }
            }

            .card-body-separator {
                margin: 0.4em .8em;
            }

            .tag-container {
                margin: 0 0.7em;
                height: 1.3em;

                .tag {
                    padding-right: .8em;

                    span {
                        font-size: 9pt;
                        font-family: $font-emphasis;
                    }
                }
            }
        }
    }
}