// From the uniguest branding guidelines:

// Base Palette

$primary-color: #20A0DB;
$primary-color-light: #58B8E4;
$primary-color-lighter: #8FCFED;
$primary-color-lightest: #C7E7F6;

$secondary-color: #FFC52B;
$secondary-color-light: #FFD460;
$secondary-color-lighter: #FFE295;
$secondary-color-lightest: #FFF1CA;

$accent-color: #5E3993;
$accent-color-light: #866BAE;
$accent-color-lighter: #AE9CC9;
$accent-color-lightest: #D7CEE4;

// Greyscale Palette

$greyscale-darker: #454444;
$greyscale-dark: #898989;
$greyscale-medium: #BFBFBF;
$greyscale-light: #CED5DE;
$greyscale-lighter: #EDEDED;
$greyscale-lightest: #F5F5F5;

// Text Colours

$text-primary-color: #222222;
$text-primary-color-light: #595959;
$text-primary-color-lighter: #919191;
$text-primary-color-lightest: #C8C8C8;

$text-secondary-color: #39557C;
$text-secondary-color-light: #6B809D;
$text-secondary-color-lighter: #9CAABE;
$text-secondary-color-lightest: #CED5DE;

// UI Element Colours

$success-color: #6CBF8C;
$success-color-light: #73BE8B;
$success-color-lighter: #9ED0AE;
$success-color-lightest: #CAE3D2;

$warning-color: #EB9C1A;
$warning-color-light: #EDB251;
$warning-color-lighter: #F0C887;
$warning-color-lightest: #F2DFBE;

$error-color: #E55162;
$error-color-light: #E54447;
$error-color-lighter: #EA7F81;
$error-color-lightest: #F0BABB;

$info-color: #2261CF;
$info-color-light: #5786D8;
$info-color-lighter: #8BABE2;
$info-color-lightest: #C0D0EB;

// Other Colours

$legacy-rcm-blue: #39557C;
$legacy-rcm-red: #E55162;
$legacy-rcm-green: #6CBF8C;
$body-background-color: #f0f1f5;
$disabled-color: #acacac;
$btn-focused-color: #39557C;

$white: #FFFFFF;
$light-grey: #D9DCE3;
$grey: #818893;
$dark-grey: #707070;

// Logo

$logo-brand-color: #FFC52B;
$icon-default: #b2b5c1;

$table-text: #939BA7;
$table-even-bg: #F5F6F8;
$inactive-color: #EAB840;
$disabled-color: #acacac;

// Fonts

$font-title: 'Proxima Nova 32 Extrabold';
$font-heading-1: 'Proxima Nova 24 Bold';
$font-heading-2: 'Proxima Nova 20 Regular';
$font-heading-3: 'Proxima Nova 16 Extrabold';
$font-heading-4: 'Proxima Nova 14 Regular';
$font-button: 'Proxima Nova 16 Medium';
$font-body: 'Proxima Nova 12 Regular';
$font-caption: 'Proxima Nova 10 Regular';
$font-emphasis: 'Proxima Nova 12 Bold';
$font-link: 'Proxima Nova 12 Regular Underline';

// Misc

$main-grid: 1320px;
$breakpoint-l: 1680px;

$faded-grey: #C6C9CE;
$alert-color: #E55162;
$logo-brand-color: #daa900;
$icon-default: #b2b5c1;
$body-bg: #f0f1f5;
$base-color: #313D65;

// Legacy

$base-color-headers: lighten(desaturate(adjust-hue($base-color, -9), 9.00), 14.90);
$base-color-lighter: lighten(desaturate(adjust-hue($base-color, -12), 2.75), 33.73);
$base-color-darker: lighten(desaturate(adjust-hue($base-color, 3), 1.00), 9.61);
$base-icons-color: lighten(saturate(adjust-hue($base-color, -6), 16.68), 41.57);
$base-text-darker: lighten(desaturate(adjust-hue($base-color, -8), 10.44), 2.16);

$base-light-color: scale-color($base-color, $lightness: +35%);
$base-light-text: scale-color($base-color, $lightness: +45%);
$base-dark-color: scale-color($base-color, $lightness: -15%);
$base-text-color: scale-color($base-color, $lightness: +25%);
$base-btn-primary: scale-color($base-color, $lightness: +25%);