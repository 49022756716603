@import "../../styles/base.scss";

.options-dropdown {

    .options-dropdown-button {
        border: none !important;
        background-color: transparent !important;
        padding: 0 .4em 0 0 !important;

        &:hover {
            background-color: transparent !important;
            border-color: darken($color: $primary-color, $amount: 10) !important;
        }

        &::after {
            display: none;
        }

        svg {
            scale: 1.4;
        }

    }

    .options-dropdown-menu {
        box-shadow: none;
        border: 1px solid $greyscale-medium;
        min-width: fit-content;
        padding: .3em;

        a {
            color: $text-primary-color;
            font-size: 14px;
            font-family: $font-body;

            &:hover {
                color: white;
                background: $primary-color;
                border-radius: 2px;
            }
        }
    }
}