@import "../../styles/base.scss";

.reserva-login-container {
    background-color: #f5f6f8;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.reserva-login {
    width: 400px;
    position: relative;
    font-family: $font-body;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000010;
    background-color: $white;

    svg {
        margin: 0 1.5em 2em 1.5em;
    }

    form {
        input {
            height: 50px;
            outline: none;
            font-family: $font-body;
            font-size: 15px;
            margin: 1rem 0;
            border-radius: 4px;
            border: none;
            box-shadow: 0px 3px 10px #00000010;

            &:hover {
                border: none;
            }

            &:focus {
                box-shadow: 0px 3px 10px #00000010;
                border: 1px solid $greyscale-darker;
            }
        }

        a {
            padding: 1rem 0 1rem 0;
            color: $text-secondary-color-light;
            font-family: $font-emphasis;
            font-size: 12px;

            &:hover {
                text-decoration: underline;
                color: $text-secondary-color;
            }
        }

        button {
            &.btn-primary {
                margin: 0 auto;
                display: flex;
                width: 100%;
                text-align: center;
                justify-content: center;
                background-color: $primary-color;
                border-radius: 25px;
                border-color: $primary-color;
                font-size: 12px;

            }

            &:hover {
                background-color: $btn-focused-color;
                border-color: $btn-focused-color;
            }

            &:disabled {
                background-color: $greyscale-medium;
                border-color: $greyscale-medium;
            }
        }
    }

    .idp-login {
        padding: 2rem 0 1rem 0;
    }

    .invalid {
        color: #dc3545;
        font-weight: bold;
        font-size: small;
    }

}