@import "../../styles/base.scss";

.calendar-small {

    font-size: small;
    padding: 0 2.5em 1.5em 2.5em;

    .small-month-navigation {
        margin-bottom: 10px;
        text-align: center;
    }

    .small-month-name {
        color: $text-primary-color;
        font-size: 11pt;
        font-weight: bold;
        padding-top: .65em;
        padding-left: 0;
        padding-right: 0;

        .year {
            font-weight: normal;
            color: $text-primary-color-lighter;
        }
    }

    .btn-arrow,
    .btn-arrow:focus {
        border: none !important;
        background-color: white !important;
        padding: .22em !important;

        svg {
            transition-duration: 200ms;
            transform: scale(1);

            &.svg-flip-y {
                transform: scale(-1, 1);
            }
        }
    }

    .btn-arrow:hover {
        border: none !important;
        background-color: white !important;


        svg {
            transition-duration: 200ms;
            transform: scale(1.5);

            &.svg-flip-y {
                transform: scale(-1.5, 1.5);
            }
        }
    }

    .small-days-of-week {
        color: $text-primary-color-lighter;
        font-size: smaller;
        font-weight: bold;
        display: flex;
        justify-content: space-around;
        margin-bottom: 1em;

        .col {
            padding: 0;
        }
    }

    .small-calendar-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .small-day-cell {
        color: $text-primary-color-light;
        width: 14.28%;
        text-align: center;
        padding: .15em 0;

        div {
            padding: .3em 0;

            &.grey-day {
                color: $text-primary-color-lightest;
            }

            &.available {
                background-color: $primary-color;
                color: white;
            }

            &.unavailable {
                background-color: $greyscale-light;
                color: white;
            }

            &.left-end {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                margin-left: .15em;
            }

            &.right-end {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                margin-right: .15em;
            }

            &.today {
                font-weight: bold;
                text-decoration: underline;
            }
        }

    }

}