@import "../../styles/base.scss";

.desks-page-wrapper {

    .groups-and-desks-container {
        display: flex;

        .page-title {
            h1 {
                font-size: x-large !important;
            }
        }

        .rooms-wrapper {
            .page-header {
                padding: 1rem 0 0 0 !important;
                margin-bottom: 0 !important;
            }

            .card-container {
                margin-top: -.9em;

                >div {
                    bottom: 2rem;
                }
            }

            .card {
                height: auto;
                width: 300px;
                height: 160px;
                max-width: inherit;
                flex: inherit;
                margin: .94rem;


                .card-body {
                    flex: inherit;
                }
            }

            .card-id {
                display: flex;
                align-items: center;
                margin: 1rem 0;

                .card-section {
                    flex: 1;

                    span {
                        color: $text-primary-color;
                        font-size: 20px;
                        font-family: $font-emphasis;
                    }
                }
            }

            .search-box {
                span {
                    background-color: #fff;
                }

                input {
                    outline: none;
                    box-shadow: none;
                    padding-left: 0;
                    height: 43px;
                }
            }
        }
    }

    .booking-controls-container {
        display: flex;
        justify-content: space-between;

        form {
            width: 90%;
        }
    }

    .error-message {
        font-family: $font-emphasis;
        width: 100%;
        font-size: .875em;
        color: $error-color;
    }
}