@import "../../styles/base.scss";

.control-form {
    padding-left: 0.5em;

    .form-label {
        font-family: $font-emphasis;
    }

    .btn-search {
        height: 45px;

        svg {
            transform: scale(-1, 1);
        }

        &:disabled {
            background-color: #BFBFBF !important;
            border-color: #BFBFBF !important;
        }
    }

    .col-2 {
        padding: 0 .3em;
        width: 15%;

        &.date-col {
            padding-right: 1em;
        }

        &.all-day-col {
            width: 20%;
            display: flex;
            align-items: flex-end;

            .toggle-switch-container {
                margin-left: 1em;

                .toggle-switch {
                    margin-bottom: .45em;
                }
            }

            .label {
                padding-bottom: .8em;

                &.checkbox {
                    margin-bottom: -.1em;
                }

                &.all-day-label {
                    font-size: 14px;
                }
            }
        }
    }

    input {
        height: 44px;
    }
}