@import "../../styles/base.scss";

.toggle-switch-container {

    .toggle-switch.label {
        width: 36px;
        height: 18px;
        border-radius: 15px;
        border: 2px solid $greyscale-dark;
        margin: 10px;
        position: relative;
        display: inline-block;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            right: 5px;
        }

        &::after {
            content: '';
            width: 30%;
            height: 70%;
            background-color: $greyscale-dark;
            position: absolute;
            border-radius: 50%;
            margin-left: 3px;
            margin-top: 2px;
            transition: transform linear 200ms;
        }

        &.disabled {
            background-color: $greyscale-light !important;
        }
    }

    input[type="checkbox"]:checked+.toggle-switch.label {
        background-color: $primary-color;
        border: 2px solid $primary-color;

        &::before {
            content: '';
            left: 5px;
        }

        &::after {
            background-color: white;
            transform: translateX(18px);
        }
    }
}