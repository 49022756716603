@import "../../styles/base.scss";

.modal-dialog {
    .modal-header {
        font-family: $font-heading-1;
        color: $text-primary-color;
        border: none;

    }

    .modal-body {

        font-family: $font-body;
        line-height: 1.5rem;

        .groups-container {
            height: 250px;

            .scroll-container {
                overflow: auto;
                position: absolute;
                bottom: 0;
                top: 0;
                left: 0;
                right: 0;
            }

            .main-group {
                li {
                    font-family: $font-button;
                    color: $text-primary-color-light;
                    font-size: 14px;

                    &.active {
                        >div {
                            label {
                                font-family: $font-button;
                                color: $primary-color;
                            }
                        }
                    }

                    >div {
                        align-items: center;
                        cursor: pointer;

                        label {
                            pointer-events: none;
                        }

                    }
                }

                >li {
                    padding: .1rem;

                    &.active {
                        >div {
                            font-weight: bold;
                            color: $text-primary-color;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            padding-left: 1rem;

                            >li.active {
                                >div {
                                    >label {
                                        font-weight: bold;
                                        color: $text-primary-color;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

    }

    .modal-footer {
        font-family: $font-body;
        border: none;

        .double-btn {
            width: 100%;
            justify-content: space-between;

            button {
                flex: 0 0 calc(50% - .5rem);
            }
        }

        .btn-solid {
            background-color: $primary-color;
            color: white;
            font-family: $font-button;
            font-size: 16px;
            border-radius: 50px;
            border: 1px solid $primary-color;

            svg {
                fill: white !important;
            }

            &:hover {
                color: white;
                border-color: $btn-focused-color;
                background-color: $btn-focused-color;
            }
        }

        .btn-outline {
            background-color: $white;
            color: $text-primary-color;
            font-family: $font-button;
            font-size: 16px;
            border-radius: 50px;
            border: 1px solid $greyscale-medium;

            svg {
                fill: white !important;
            }

            &:hover {
                color: $text-primary-color;
                border-color: $greyscale-dark;
                background-color: white;
                box-shadow: 0px 0px 10px 0px #4040401F;
            }
        }
    }

}