@import "../../styles/base.scss";


.card-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0 -.8rem;

    .card {
        margin: .5em !important;
        min-width: 300px;
        border: none;
        border-radius: 7px;
        flex: 0 0 calc(33% - 1.9rem);
        border: 1px $greyscale-medium solid;
        padding: 4px;

        height: 600px;

        &.busy {
            opacity: 60%;
        }

        &.added {
            border: 4px solid $secondary-color;
            padding: 1px;
        }

        &.recurring {
            height: fit-content !important;
        }

        .card-header {
            background: white;
            height: 55px;
            font-size: 14px;
            display: flex;
            align-items: center;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            position: relative;
            padding-left: .8em;

            >.card-head {
                width: 100%;
                display: flex;
                flex-direction: column;
                flex: 1;

                .card-header-description {
                    font-size: 12px;
                }
            }


            .desk-name-group-container {
                display: flex;
                min-width: 75%;

                &.busy {
                    min-width: 100%;
                }

                .desk-name {
                    color: $legacy-rcm-blue;
                    font-family: $font-title;
                    font-size: 22pt;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                    overflow: hidden;
                    min-width: 20%;
                }

                .desk-group-hierarchy {
                    min-width: 20%;
                    max-width: 70%;
                    display: inline-grid;

                    span {
                        margin-left: .5em;
                        font-size: 11pt;

                        text-overflow: ellipsis;
                        text-wrap: nowrap;
                        overflow: hidden;

                        &:first-child {
                            font-family: $font-emphasis;
                            padding-top: .6em;
                            margin-bottom: -.9em;
                        }
                    }
                }
            }


            .btn-add-container {
                display: inline-flex;
                flex-direction: row-reverse;
                width: -webkit-fill-available;

                .btn {
                    height: 50%;
                    padding: .5em .9em;
                }
            }
        }

        .card-body {
            padding-top: .1em;
            padding-bottom: .1em;

            .status-label {
                font-family: $font-heading-3;
                font-size: 11pt;

                span {
                    font-family: $font-emphasis;
                }
            }

            .booked-by-label {
                font-size: 11pt;
            }

            .date-available {
                margin-top: -.4em;

                span {
                    font-size: 10pt;

                    &:first-child {
                        font-family: $font-emphasis;
                    }
                }
            }

            .timezone {
                span {
                    font-size: 10pt;

                    &:first-child {
                        font-family: $font-emphasis;
                    }
                }
            }

            .tag-container {
                padding-top: 1.5em;

                .tag {
                    padding-right: .8em;

                    span {
                        font-size: 9pt;
                        font-family: $font-emphasis;
                    }
                }
            }

            .calendar-acccordion {

                .accordion-header {

                    .accordion-button {
                        border: none !important;
                        background-color: transparent !important;
                        box-shadow: none !important;
                        padding: 0;
                        opacity: 60%;
                    }
                }

                .accordion-body {
                    padding: 0;
                    border: none;
                }

                .accordion-item {

                    border: none;
                }
            }
        }


    }
}

.tooltip {

    .tooltip-inner {
        color: $text-primary-color;
        background-color: white;
        line-height: 24px;
        padding: 12px !important;

        line-height: 18px !important;

        .tooltip-header {
            font-weight: bold;
        }

        hr {
            margin: 5px;
        }

        .group-separator {
            width: 10px;
            height: 10px;
        }

    }

    .tooltip-arrow {
        &:before {
            border-top-color: white !important;
        }
    }
}