@import "../../styles/base.scss";

.rooms-wrapper {

    flex: 1 1;
    display: flex;
    flex-direction: column;

    .pagination-controls {
        padding-top: 1.5em;
    }

    .pagination {
        .page-link {
            color: $text-primary-color-light;
            border: 1px solid $greyscale-medium;
            border-radius: 5px;
            margin-right: 5px;

            &:hover {
                background-color: $primary-color !important;
                border-color: $primary-color;
                color: $white;
            }
        }

        .pagination-ellipsis {
            .page-link {
                color: $text-primary-color-light;
                border: 1px solid $greyscale-medium;
                border-radius: 5px;
                margin-right: 5px;
                background-color: $greyscale-lightest;

                &:hover {
                    background-color: $greyscale-lightest !important;
                    cursor: default;
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .page-item.active .page-link {
            color: white;
            border-color: $primary-color;
            background-color: $primary-color;
        }

        .pagination-arrow-left {
            .page-link {
                background-color: white;

                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;

            }

            &.disabled {
                .page-link {
                    color: $text-primary-color-light;
                    border: 1px solid $greyscale-medium;
                    background-color: $greyscale-lightest;
                }
            }
        }

        .pagination-arrow-right {
            .page-link {
                background-color: white;

                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
            }

            &.disabled {
                .page-link {
                    color: $text-primary-color-light;
                    border: 1px solid $greyscale-medium;
                    background-color: $greyscale-lightest;
                }
            }
        }
    }

    .selector-label {
        background: white;
        color: $text-primary-color;
    }

    select {
        color: $text-primary-color-light;
    }

    .page-header {
        min-width: 800px;
        margin-left: 1rem;
        align-items: baseline !important;

        .dropdown-active-icon {
            transform: translateX(-16px);
            position: absolute;
        }
    }

    .page-content {
        height: calc(100% - 120px) !important;
    }

    .scroll-container {
        overflow-x: hidden !important;
        padding-left: 1rem;
        padding-top: .5em;
    }

    .filter-box {
        button {
            margin-right: 1rem;
        }
    }

    .card-container {
        .card-header {
            cursor: pointer;

            span {
                font-size: 12px;
                font-family: $font-heading-2;

                strong {
                    font-size: 16px;
                    font-family: $font-heading-1;
                }
            }

            .scheduling-system-logo {
                width: 1.8rem;
                height: auto;
                margin-right: 0.5rem;

                pointer-events: none;
            }

            .scheduling-system-logo-thin {
                width: 3rem;
                height: auto;
                margin-right: 0.5rem;
                filter: contrast(4);

                pointer-events: none;
            }
        }

        .more-tags {
            font-family: $font-body;
            font-size: 12px;
            display: inline-flex;
            text-align: left;
            text-decoration: underline;
            align-items: center;
            padding-left: 5px;
            color: $text-secondary-color-light;
            opacity: 1;
            cursor: pointer;
        }

        .error-container {
            padding-top: 15px;

            .error-icon {
                &:before {
                    content: "!";
                    background-color: $error-color;
                    color: white;
                    display: inline-block;
                    width: 21px;
                    height: 21px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 22px;
                    font-size: 16px;
                    font-family: $font-emphasis;
                }
            }

            .error-text {
                margin-left: 5px;
                margin-right: 15px;
                text-align: left;
                letter-spacing: 0px;
                font-family: $font-caption;
                font-size: small;
                color: $text-primary-color-light;
                opacity: 1;
            }
        }
    }
}

.confirm-btn {
    position: absolute;
    top: 9em;
    right: 0em;
}

.all-day-booking-summary {
    margin: .5em;
    max-height: 100px;
    overflow: auto;

    p {
        margin: 0;
    }
}