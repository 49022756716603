@import "../../styles/base.scss";

.bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid $light-grey;
    background: $white;
    padding: 0;
    margin: 0;
    z-index: 999;

    .row {
        height: 9vh;
        margin: 0 0.5em;

        .col {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 100%;
                height: 26px;
                fill: rgb(129, 136, 147);
            }

            .btn-add {
                background-color: $primary-color;
                border: none;
                border-radius: 100px;
                position: fixed;
                height: 40px;
                width: 40px;

                svg {
                    scale: 1.3;
                }
            }

            a {
                &.active {
                    svg {
                        fill: $secondary-color;

                        * {
                            fill: $secondary-color;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}