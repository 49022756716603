@import "../../styles/base.scss";

.main-nav {
    width: 90px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 140px);

    @media (min-width: $breakpoint-l) {
        width: 200px;
    }

    a {
        padding: 23px 0px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        position: relative;
        height: 68px;
        text-decoration: none;

        @media (min-width: $breakpoint-l) {
            justify-content: initial;
            padding-left: 32px;
            align-items: center;
        }

        svg {
            fill: $text-primary-color-light;
            width: 25px;
            height: 25px;
            padding: 1px;
            box-sizing: border-box;

            * {
                fill: $text-primary-color-light;
            }
        }

        span {
            display: none;
            padding-left: .8rem;
            color: $text-primary-color;
            font-family: $font-button;
            font-size: 15px;

            @media (min-width: $breakpoint-l) {
                display: initial;

            }
        }


        &.active {
            svg {
                fill: $secondary-color;

                * {
                    fill: $secondary-color;
                    opacity: 1;
                }
            }

            >span {
                color: $primary-color;
            }

            &:after {
                content: "";
                width: 6px;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                background: $primary-color;
            }

        }
    }

    object {
        pointer-events: none;
    }

}

.sidenav-tooltip {
    &.tooltip {

        .tooltip-inner {
            color: $text-primary-color;
            background-color: white;
            line-height: 24px;
            padding: 5px 10px;
        }
    }

    &.bs-tooltip-end {
        @media (min-width: $breakpoint-l) {
            display: none !important;
        }

        .tooltip-arrow {
            top: 50% !important;
            transform: translate(0px, -50%) !important;

            &:before {
                border-right-color: white !important;
            }

        }
    }

    &.bs-tooltip-bottom {
        .tooltip-arrow {
            &:before {
                border-bottom-color: white !important;
            }
        }
    }

    &.bs-tooltip-top {
        .tooltip-arrow {
            &:before {
                border-top-color: white !important;
            }
        }
    }
}

#tooltip-right {
    margin-left: 10px;
    margin-top: -2px
}